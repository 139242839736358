<template>
  <section class="wizard container">
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      color="#E30713"
      :height="145"
      :width="145"
      :opacity="1"
      backgroundColor="#eeeeee"
      :z-index="4"
      is-full-page
    ></loading>
    <Navigation :steps="navigationConfig"/>
    <Tabs v-if="isTabsVisible"
          :tabs="sectors"
          :activeTab="sectorIndex"
          @deleteSector="requestRemovingSector"
          @switchSector="navigate($event)"
    />
    <div class="wizard__wrapper">
      <h2 class="wizard__title">
        <span>{{ title.main }}</span>
        <span class="wizard__subtitle" v-if="title.subtitle">{{ title.subtitle }}</span>
      </h2>
      <div class="wizard__step--container">
        <component
          :is="currentComponent"
          :sector="sector"
          @checkStepValidation="checkStepValidation"
        ></component>
      </div>
      <aside v-if="showAside" class="wizard__aside">
        <ConstructionScheme
          :url="sector.image"
          :canShow="isThirdStep"
          :canZoom="isThirdStep"
          @zoomScheme="onZoom($event)"
        />
      </aside>
      <NavigationFooter :validation="validation" @navigate="navigate($event)"/>
    </div>
    <Modal :action="modalAction"
           :isModalOpen="isModalOpen"
           :modalData="modalData"
           :src="src"
           @action="handleModalAction($event)"
    />
  </section>
</template>

<script>
import Loading from 'vue-loading-overlay'
import NavigationMixin from '@/components/mixin/NavigationMixin'
import Navigation from '@/components/smart/Navigation'
import ConstructionScheme from '@/components/dump/ConstructionScheme'
import Tabs from '@/components/smart/TabsComponent'
import { mapActions, mapGetters } from 'vuex'
import NavigationFooter from '@/components/dump/NavigtionFooter'
import Modal from '@/components/dump/Modal'
import ModalMixin from '@/components/mixin/ModalMixin'
import { calc, sendStatistic } from '@/utils/sendStatistic'
import { stepNumbers } from '@/utils/stepNumbers'
import { getSectorConstructionTypeId } from '@/utils/utils'
import { ConstructionTypes } from '@/utils/types'

const components = {
  0: () => import('@/components/smart/ConstructionTypes'),
  1: () => import('@/components/smart/SystemFilter'),
  2: () => import('@/components/smart/ConstructionParams')
}
const lastWizardStep = Object.keys(components).length - 1

export default {
  mixins: [NavigationMixin, ModalMixin],
  components: {
    Loading,
    Navigation,
    Tabs,
    ConstructionScheme,
    NavigationFooter,
    Modal
  },
  data: () => ({
    deleteIndex: null
  }),
  methods: {
    ...mapActions({
      getContent: 'getContent'
    }),
    requestRemovingSector(evt) {
      this.deleteIndex = evt.index
      this.modalAction = 'delete'
      this.isModalOpen = !this.isModalOpen
    },
    showDetails() {
      this.modalAction = 'details'
      this.modalData = { ...this.selectedSystem }
      this.isModalOpen = !this.isModalOpen
    }
  },
  mounted() {
    this.getContent()
    sendStatistic(`${calc}-step${this.sector?.currentStep + 1}`)
  },
  watch: {
    selectedSystem() {
      if (this.selectedSystem !== null) this.showDetails()
    },
    currentComponent() {
      if (this.sector?.currentStep !== stepNumbers.result) {
        this.sendMetrics()
        this.sendStatistic(`step${this.sector?.currentStep + 1}`)
      }
    }
  },
  computed: {
    ...mapGetters({
      loadingStatus: 'getWizardLoadingStatus',
      getModalValues: 'getModalValues',
      getConstructionTypes: 'getConstructionTypes'
    }),
    title() {
      return this.titles[this.currentStepNumber]
    },
    isLoading() {
      return this.loadingStatus
    },
    currentComponent() {
      return components[this.currentStepNumber] || components[lastWizardStep]
    },
    selectedSystem() {
      return this.getModalValues.selectedSystem
    },
    isCombinedLocation() {
      return this.sector.constructionType.isCombinedLocation
    },
    showAside() {
      return (this.sector.image && this.isThirdStep) ||
        (getSectorConstructionTypeId(this.getConstructionTypes, this.sector) === ConstructionTypes.floor && this.sector.currentStep === 0)
    }
  }
}
</script>

<style scoped lang="sass">
.wizard
  width: 100%
  &__wrapper
    display: grid
    grid-template-rows: auto
    background: $white
    border-radius: $block-radius
    grid-template-columns: 1fr
    +media((grid-template-areas: (0: 'title title title' 'step step step' 'aside aside aside' 'footer footer footer', 768: 'title .' 'step aside' 'footer aside')))
    +media((padding: (0: rem(30), 1170: rem(50))))
  &__wrapper:has(aside)
    +media((grid-template-columns: (0: 1fr, 480: 1fr 200px, 1024: minmax(0, rem(780)) 260px)))
    +media((grid-gap: (0: 0 rem(30), 1170: 0 rem(60))))
  &__title
    grid-area: title
  &__subtitle
    display: inline-block
    margin: rem(15) 0 0
    @extend %16
    line-height: 1.3
  &__step--container
    display: flex
    grid-area: step
  &__aside
    grid-area: aside
.tabs + .wizard__wrapper
  border-top-left-radius: 0
  +media((border-top-right-radius: (0: 0, 490: $block-radius)))
</style>
